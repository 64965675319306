import React from 'react'
import {
  Breadcrumbs,
  Construction,
  Hero,
  Page,
  ServiceContact,
} from '@components'

const meta = {
  title: 'Izvođenje radova protupožarne zaštite | FSB d.o.o.',
  description: 'U svrhu ispunjavanja visoko postavljenih zahtjeva surađujemo s najboljim monterskim tvrtkama u Republici Hrvatskoj.',
  image: require('@assets/images/about.jpg'),
}

const Homepage = () => (
  <Page
    isHomepage
    meta={meta}
  >
    <Breadcrumbs
      links={[
        { link: '/', name: 'Početna'},
        { link: '/usluge', name: 'Usluge'},
        { link: '/usluge/izvodjenje-radova-protupozarne-zastite', name: 'Izvođenje radova protupožarne zaštite'},
      ]}
    />
    <Hero
      title="Usluga"
      subtitle="Izvođenje radova protupožarne zaštite"
      description={meta.description}
    />
    <Construction />
    <ServiceContact id="izvođenje" />
  </Page>
)

export default Homepage